input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}

.c-checkbox input[type="checkbox"]:checked + span,
.c-radio input[type="checkbox"]:checked + span,
.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="radio"]:checked + span {
  border-color: #003366;
  background-color: #003366;
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.input-xs,
.form-group-xs .form-control {
  height: 25px;
  padding: 5px 10px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 3px;
}

select {
  &.input-xs,
  &.form-group-xs .form-control {
    height: 25px;
    line-height: 30px;
  }
}

textarea {
  &.input-xs,
  &.form-group-xs .form-control {
    height: auto;
  }
}

select[multiple] {
  .input-xs,
  .form-group-xs .form-control {
    height: auto;
  }
}

.customer-table_form-wrapper > form > input[type="search"] {
  font-size: 10px !important;
}

.max-w-50 {
  max-width: 50% !important;
}

.w50 {
  width: 50% !important;
}

/*Customer Summary Page
----------------------------------*/

div.page_customer-summary {
  &.panel-body {
    padding-top: 0;
  }

  &.panel-heading {
    padding-bottom: 0;
  }
}

table.table thead {
  .sorting_asc::before,
  .sorting_desc::before {
    content: "" !important;
    padding-right: 0 !important;
  }
}

.control-system-edit_.enable-vk {
  margin-left: 1.3333rem;
}

/*select.form-control {
    border: 1px solid #3A3F51!important; //Removes border
    -webkit-appearance: none;  //Removes default chrome and safari style
    -moz-appearance: none; // Removes Default Firefox style
    background: #fff url(/assets/img/select-arrow.png) no-repeat 99% center;
    width: 100%; //!*Width of select dropdown to give space for arrow image

    text-overflow: "";  //Removes default arrow from firefox
    color: #3a3f51;
    border-radius: 4px;
    padding: 5px;

}*/

.c-checkbox,
.c-radio {
  margin-right: 4px;
}

.c-checkbox *,
.c-radio * {
  cursor: pointer;
}

.c-checkbox input,
.c-radio input {
  opacity: 0;
  position: absolute;
  margin-left: 0 !important;
}

.c-checkbox span,
.c-radio span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: -20px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  @include dmp-border();
  margin-right: 5px;
  text-align: center;
}

.c-checkbox span:before,
.c-radio span:before {
  margin-left: 1px;
}

.c-checkbox:hover span,
.c-radio:hover span {
  border-color: #5d9cec;
}

.form-inline {
  .c-checkbox span,
  .c-radio span {
    margin-left: 0;
  }
}

.c-checkbox.c-checkbox-rounded span,
.c-radio.c-checkbox-rounded span,
.c-checkbox.c-radio-rounded span {
  border-radius: 500px;
}

.c-radio {
  &.c-radio-rounded span,
  span {
    border-radius: 500px;
  }
}

/* override for radio */

/* the icon */

.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 1px;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle;
}

/* Checked state */

.c-checkbox,
.c-radio {
  /* override for radio */
  /* Disable state */
  /* override for radio */
}

.c-checkbox input[type="checkbox"]:checked + span:before,
.c-radio input[type="checkbox"]:checked + span:before,
.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
  color: white;
  opacity: 1;
  transition: color 0.3s ease-out;
}

.c-checkbox input[type="checkbox"]:checked + span,
.c-radio input[type="checkbox"]:checked + span,
.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="radio"]:checked + span {
  border-color: $state-primary;
  background-color: $state-primary;
}

.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="radio"]:checked + span {
  background-color: $bleached-white;
}

.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
  color: rgba($state-primary, 0.6);
}

.c-checkbox input[type="checkbox"]:disabled + span,
.c-radio input[type="checkbox"]:disabled + span,
.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
  border-color: $dmp-layout-border-darker !important;
  background-color: $dmp-layout-border-darker !important;
}

.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
  background-color: $dmp-white-inverse !important;
}

.c-checkbox input[type="radio"]:disabled + span:before,
.c-radio input[type="radio"]:disabled + span:before {
  color: $dmp-layout-border-darker;
}

.nav-pills > li.active > a {
  color: $dmp-white-inverse;
  background-color: $state-primary;

  &:hover,
  &:focus {
    color: $dmp-white-inverse;
    background-color: $state-primary;
  }
}

.nav.nav-pills .active > a {
  background-color: $state-primary;
}

section.armed-action-column {
  margin: 0 0.5em;
}

div {
  &.error-modal.modal-header {
    background-color: $dmp-red;
    color: white;
  }

  &.status-modal.modal-header {
    background-color: $dmp-white-inverse;
    color: #656565;
  }
}

div.modal-body.tabset-modal-body {
  div.mt-sm {
    .tab-content {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

//inserts a blank space before dynamic groups for proper spacing

.panel-group:before {
  content: "\00a0 ";
}

#flotTip {
  background-color: rgba(0, 0, 0, 0.75) !important;
  border-color: transparent !important;
  border-radius: 2px !important;
  color: #f1f1f1;
  font-size: 12px !important;
  padding: 5px;
  position: relative;
  z-index: 5;
}

//extended programming input fields

input[name="name"],
select[name="tipe"] {
}

//label - zone number wrapper
.zone-number {
  padding: 26px 15px 0 0;
}

//form table grid
.form-control-grid {
  width: 100;
  margin: 1em 0;

  .form-control-grid-cell {
    display: table-cell;
    width: 1%;
    float: none;
  }
}

.checkbox-inline.no_indent,
.checkbox-inline.no_indent + .checkbox-inline.no_indent {
  margin-left: 0;
  margin-right: 10px;
}

.checkbox-inline.no_indent:last-child {
  margin-right: 0;
}

label.checkbox-inline {
  font-weight: bold;
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  select.form-control.form-control-300,
  input.form-control.form-control-300 {
    max-width: 300px;
  }

  select.form-control.form-control-377,
  input.form-control.form-control-377 {
    max-width: 377px;
  }

  .form-control.form-control-200 {
    max-width: 195px;
    min-width: 195px;
  }

  #col-rt > div.user-code-search-wrap {
    padding-top: 1.933333em;
  }
}

.sub-table-ext-info-block {
  font-size: 1.2rem;
  display: inline-block;
}

select.form-control.form-control.form-control-440 {
  max-width: 80%;
}

.zone-action-btn-adustment {
  padding: 0 15px;
}

@media all and (max-width: 1023px) and (min-width: 769px) {
  .col-btn-zn-info-margin-adj {
    margin-top: 2em;
  }
}

.tool-tip {
  cursor: hand;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: $state-primary;
}

fieldset {
  border-bottom: 1px solid #efefef;
  border-radius: 4px;
}

fieldset.fieldset-camera {
  padding-bottom: 1em;
  border: 1px solid #efefef;
  border-bottom-width: 1px !important;
}

//For Company Store/Secura Settings form pages
.highlighted {
  background: $dmp-light-gray;
  border: 1px solid $global-border;
  padding: 5px 10px;
  //line-height: 30px;
}

//for giving form-groups a max-expansion width
.constrained-width-350 {
  max-width: 350px;
}

.read-only-data-no-input {
  padding: 7px 17px;
}

.appear-disabled {
  background: #edf1f2;
}

.stripe-colored {
  background: $brownish-grey;
}

//Full programming field display
.programming-data {
  font-size: $font-size-base;
  font-weight: 500;
  color: $dmp-text-standard;
  -ms-word-break: break-all;
  word-break: break-all;

  &.no-break {
    -ms-word-break: keep-all;
    word-break: keep-all;
  }

  &.break-word {
    -moz-word-break: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    word-break: break-word;
  }
}

//help block for use underneath form-fields
.help-block {
  display: block; // account for any element using help-block
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
  color: lighten($text-color, 30%); // lighten the text some for contrast
}

//reduces margin under form-group with help-blocks for uniformity
.form-group.with-help-block {
  margin-bottom: 0.2rem;
}

.has-error-ada {
  @include form-control-validation($dmp-red-ada, $dmp-red-ada, $dmp-red-ada);

  .deactivate-error {
    color: white !important;
  }

  .status-error {
    color: darken($dmp-blue-ada, 10%) !important;
  }
}

//toggle in header area of bs panel
.panel-header-toggle {
  float: right;
  display: flex;
  height: 100%;
  justify-content: flex-center;

  &--active {
    color: $dmp-blue;

    &.ng-enter {
      transition: all 0.4s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &.ng-leave {
      transition: all 0.2s ease-in;
      opacity: 0;
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
      opacity: 0;
      -webkit-transform: translateX(-1rem);
      -moz-transform: translateX(-1rem);
      -ms-transform: translateX(-1rem);
      -o-transform: translateX(-1rem);
      transform: translateX(-1rem);
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
      opacity: 1;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
    }
  }

  &--inactive {
    &.ng-enter {
      transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &.ng-leave {
      transition: all 0.1s ease-in;
      opacity: 0;
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
      opacity: 0;
      webkit-transform: translateX(4rem);
      -moz-transform: translateX(4rem);
      -ms-transform: translateX(4rem);
      -o-transform: translateX(4rem);
      transform: translateX(4rem);
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
      opacity: 1;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
    }
  }
}

label {
  &.not-bold {
    font-weight: 400;
  }

  &.control-label--thin {
    font-size: var(--measure-font-12);
    font-weight: var(--font-weight-thin) !important;
  }
}

input::placeholder {
  color: var(--dark-gray);
}

input:focus::-webkit-input-placeholder {
  color: transparent;
  transition: all 0.4s ease-in;
}

input:focus::-moz-placeholder {
  color: transparent;
  transition: all 0.4s ease-in;
}

.form-control {
  &.form-control-file-input {
    border: 1px solid #ccc;
    display: flex;
    padding: 6px 12px;
    cursor: pointer;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &::-webkit-file-upload-button {
    }
  }
}

input {
  &.file-input-button {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    + label {
      color: var(--color-info-500);
      background-color: white;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      border-radius: 0.4rem;
      border: 1px solid $color-info-500;
      transition: all 0.3s ease-out;
      font-size: var(--measure-font-12);
      font-weight: var(--font-weight-ultralight);

      padding: 0.5rem 1rem;

      &:hover {
        background-color: rgba($color-info-500, 0.2);
        color: darken($color-info-500, 0.2);
        border-color: darken($color-info-500, 0.2);
        box-shadow:
          0 1px 3px rgba(0, 0, 0, 0.12),
          0 1px 2px rgba(0, 0, 0, 0.24);
      }

      &:active {
        box-shadow:
          0 0 0 rgba(0, 0, 0, 0.12),
          0 0 0 rgba(0, 0, 0, 0.24);
      }
    }
  }
}

.form {
  &__import-detail {
    font-size: var(--measure-font-12);
    color: var(--color-neutral-800);
    @include flex-config();
  }
}

.max-300-screen-sm {
  max-width: 100%;
  @media (min-width: $screen-sm) {
    max-width: 30rem;
  }
}
